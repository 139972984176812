import React from 'react'
import { MDBMask, MDBView, } from 'mdbreact'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"


class Hero extends React.Component {
  render() {
    const sources = ((this.props.type === 'news' || this.props.type === 'blog') && (this.props.ctaslug === null || this.props.ctaslug === undefined)) ? 
    [
      {aspectRatio: 1, src: '', srcSet: ''},
      {...this.props.image, media: `(min-width: 480px)`}, 
    ] 
    :
    this.props.image

   const bgImage = convertToBgImage(this.props.image)

    return (
      <BackgroundImage Tag="section" className={this.props.class} fluid={sources} alt={this.props.alt} {...bgImage} preserveStackingContext >
        <MDBView>
          <MDBMask className={ this.props.gradientClass != null ? this.props.gradientClass : 'mask d-flex justify-content-center gradient-blog' } >
          </MDBMask>
        </MDBView>
      </BackgroundImage>
    )
  }
}

export default Hero
