import React from 'react'
import { MDBCol, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/pro-solid-svg-icons'

class Card extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : 'auto'
    return (
      <MDBCol lg={this.props.collg} md={this.props.colmd} className="d-flex pb-3" >
        <MDBCard className="flex-fill">
          {this.props.link ? (
            <>
              {this.props.link.substring(0, 4) != 'http' && this.props.link.substring(0, 8) != '/static/' ? (
                <Link to={this.props.link}> {this.props.image && ( <GatsbyImage image={this.props.image} alt={this.props.alt} className="rounded-top" /> )} </Link>
              ) : (
                <a href={this.props.link} target="_blank" rel="noopener"> {this.props.image && ( <GatsbyImage image={this.props.image} alt={this.props.alt} className="rounded-top" /> )} </a>
              )}
            </>
          ) : (
            <>
              {this.props.image && (
                <GatsbyImage image={this.props.image} alt={this.props.alt} className="rounded-top" />
              )}
            </>
          )}

          <MDBCardBody className="card-blog">
            <div style={{ maxHeight: colHeight, minHeight: colHeight }}>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium mt-2 pb-3"> {this.props.title} </h3>
              {this.props.link ? (
                <>
                  {this.props.link.substring(0, 4) != 'http' && this.props.link.substring(0, 8) != '/static/' ? (
                    <p className="font-w-400 text-card-small mb-0"> <Link to={this.props.link} className="effect-noline"> {this.props.subtitle}</Link> </p> 
                  ) : (
                    <p className="font-w-400 text-card-small mb-0">{this.props.subtitle} <a href={this.props.link} className="effect-noline" target="_blank" rel="noopener">  </a> </p>
                  )}
                </>
              ) : (
                <>
                  {this.props.subtitle && (
                    <h4 className="font-w-400 text-card-small"> {this.props.subtitle} </h4>
                  )}
                </>
              )}
            </div>

            <hr />

            {this.props.link ? (
              <MDBCardText className={`font-w-400 mt-2 mb-2 ` + this.props.descriptionClass ? this.props.descriptionClass : 'text-medium'}>
                {(this.props.link.substring(0, 4) != 'http' && this.props.link.substring(0, 8) != '/static/') ? (
                  <>
                  <FontAwesomeIcon icon={faClock} className="mr-3 ico-color" />
                    <Link to={(this.props.link.indexOf('?') > -1 || this.props.link.slice(-1) == "/") ? this.props.link : '' + this.props.link + '/'} className="effect">
                      {this.props.readtime}
                    </Link>
                  </>
                ) : (
                  <>
                  <FontAwesomeIcon icon={faClock} className="mr-3 ico-color" />
                  <a href={this.props.link} className="effect" target="_blank" rel="noopener" >{this.props.readtime}</a>
                  </>
                )}
              </MDBCardText>
            ) : (
              <MDBCardText className="font-w-400 text-medium mt-2 mb-2"></MDBCardText>
            )}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}

export default Card
